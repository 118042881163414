.filedColor {
  background-color: whitesmoke;
  color: white;
}
.radius {
  border-radius: 15px 99px;
}
.con_banner{
  position: relative;
  width: 100%;
  height: 320px;
}

