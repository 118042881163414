.banner_img{
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 0%, rgba(0,212,255,1) 100%);
  height: 220px;
  width: 100%;
  border-radius: 14px;
}

.zoom {
    padding: 4px;
    transition: transform .2s;
    cursor: pointer;
  }

  .zoom:hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
  }

  .img_size{
    height: 220px;
    width: 320px;
    position: relative;
  }

  @media (max-width: 475px) and (min-width: 280px) {

    .img_size{
      height: 130px;
      width: 190px;
    }
  }