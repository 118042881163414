.banner_img{
  background: rgb(0,13,36);
  background: radial-gradient(circle, rgba(11, 40, 88, 0.619) 0%, rgba(5,3,93,0.9472163865546218) 0%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 200px;
  width: 100%;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

/* #010340 */

.singleImg{
  border-radius: 24px;
}

.bg_color {
    background: #1672e6 !important ;
}

.zoom {
    transition: transform .2s;
    border-radius: 14px;
   
  }

  .zoom:hover {
    cursor: pointer;
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(0.9); 
  }


  /*---- Banner CSS ---- */

  .img_size{
    position: relative;
    height: 220px;
    width: 320px;
  }

  @media (max-width: 768px) and (min-width: 280px) {

    .banner_img{
      background: rgb(0,13,36);
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-around;
      height: 275px;
      width: 100%;
      border-radius: 14px;
    }
    .img_size{
      height: 140px;
      width: 220px;
    }
  }


  /*--------- Explore Button -----------*/
.button_hover {
  width: 190px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
  height: 48px;
  text-align: center;
  border: none;
  background-size: 400% 100%;
  border-radius: 50px;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.button_hover:hover {
  background-position: 100% 0;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.button_hover:focus {
  outline: none;
}

.button_hover.color {
  background-image: linear-gradient(
    to right,
    #04befe,
    #1a47fc,
    #357cf5,
    #3b86f8
  );
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}