.banner_img{
  background: linear-gradient(270deg, #4949e7 20%, #3636cc 100%);;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 220px;
  width: 100%;
  border-radius: 14px;
}


.button_hover {
  width: 190px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
  height: 48px;
  text-align: center;
  border: none;
  background-size: 400% 100%;
  border-radius: 50px;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.button_hover:hover {
  background-position: 100% 0;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.button_hover:focus {
  outline: none;
}

.button_hover.color {
  background-image: linear-gradient(
    to right,
    #04befe,
    #1a47fc,
    #357cf5,
    #3b86f8
  );
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}


.img_size{
  height: 220px;
  width: 220px;
}

@media (max-width: 768px) and (min-width: 280px) {

  .banner_img{
    background: linear-gradient(270deg, #4949e7 20%, #3636cc 100%);;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 275px;
    width: 100%;
    border-radius: 14px;
  }

  .img_size{
    height: 130px;
    width: 149px;
  }
}



