.main_container{
  background-color:#0255df ;
  margin-top: 80px;
 
}


.row_box{
  padding: 100px 0 120px 0;
}


.video_frame{
  width: 100%;
  border-radius: 4px;
}


.btn_hover {
    width: 200px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    height: 50px;
    text-align: center;
    border: none;
    background-size: 400% 100%;
    border-radius: 50px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  
  .btn_hover:hover {
    background-position: 100% 0;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  
  .btn_hover:focus {
    outline: none;
  }
  
  .btn_hover.btn_color {
    background-image: linear-gradient(
      to right,
      #04befe,
      #357cf5,
      #3b86f8
    );
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
  }


  @media (min-width: 280px) and (max-width: 540px) {
   
    .row_box{
      padding: 20px 0 20px 0;
    }

    .tag_line{
      font-size: 45px;
      font-weight: 600;
      line-height: 55px;
    }

    .more_text{
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
    }

    .btn_hover {
      width: 180px;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      cursor: pointer;
      margin-top: 20px;
      height: 45px;
      text-align: center;
      border: none;
      background-size: 400% 100%;
      border-radius: 50px;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }

  }


  
  @media (min-width: 541px) and (max-width: 820px) {
  
    .row_box{
      padding: 70px 0 60px 0;
    }

    .tag_line{
      font-size: 45px;
      font-weight: 600;
      line-height: 50px;
    }

    .more_text{
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
    }
    
  }
 
  @media (min-width: 1024px) and (max-width: 2560px) {
    .tag_line{
      font-size: 56px;
      font-weight: 600;
      line-height: 80px;
    }
    .more_text{
      font-size: 20px;
      font-weight: 500;
      line-height: 35px;
    }
  }
