.banner_img{
  background: rgb(34,191,195);
  background: linear-gradient(45deg, rgba(34,191,195,1) 5%, rgba(45,70,253,0.8603816526610644) 100%);  
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  height: 220px;
  width: 100%;
  border-radius: 14px;
}

.zoom {
    transition: transform .2s;
    height: 330px;
    background-color: #E5E3E3;
  }

  .zoom:hover {
    background-color: #fff !important;
    cursor: pointer;
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
  }

/*--------- Explore Button -----------*/
.button_hover {
  width: 190px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
  height: 48px;
  text-align: center;
  border: none;
  background-size: 400% 100%;
  border-radius: 50px;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.button_hover:hover {
  background-position: 100% 0;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.button_hover:focus {
  outline: none;
}

.button_hover.color {
  background-image: linear-gradient(
    to right,
    #04befe,
    #1a47fc,
    #357cf5,
    #3b86f8
  );
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}




/*---------- Banner CSS ------------- */

  .img_size{
    height: 200px;
    width: 320px;
    position: relative;
  }

  @media (max-width: 768px) and (min-width: 280px) {

    .banner_img{
      background: rgb(34,191,195);
      background: linear-gradient(45deg, rgba(34,191,195,1) 5%, rgba(45,70,253,0.8603816526610644) 100%); 
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-around;
      height: 275px;
      width: 100%;
      border-radius: 14px;
    }
    .img_size{
      height: 130px;
      width: 149px;
    }
  }