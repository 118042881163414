.container{
    background-color: #f3ebe9;
}
.btn_hover {
    width: 200px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    height: 48px;
    text-align: center;
    border: none;
    background-size: 400% 100%;
    border-radius: 50px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  
  .btn_hover:hover {
    background-position: 100% 0;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  
  .btn_hover:focus {
    outline: none;
  }
  
  .btn_hover.btn_color {
    background-image: linear-gradient(
      to right,
      #04befe,
      #357cf5,
      #3b86f8
    );
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
  }



@media (max-width: 2560px) and (min-width: 1024px) {
    .animSize{
        height: 350px;
    }
    .title{
        font-size: 45px;
        font-weight: 600;
        line-height: 65px;
    }
}


@media (min-width: 320px) and (max-width: 760px) {

    .title{
        font-size: 30px;
        font-weight: 600;
        line-height: 40px;
      }

    .btn_hover {
        width: 160px;
        font-size: 16px !important;
        font-weight: 700 !important;
        color: #fff;
        cursor: pointer;
        margin-top: 15px;
        height: 40px;
        text-align: center;
        border: none;
        background-size: 400% 100%;
        border-radius: 50px;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
      }
}