.icon_size{
  height: 70px;
  width: 70px;
}

.animSize{
  height: 400px;
}


