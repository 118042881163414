.card_size{
  
  cursor: pointer;
}
.card_title{
  font-size: 1rem;
  text-align: center;
  /* height: 2.7rem; */
}

.fee{
  font-size: 20px;
  font-weight: 900;
}


.video_icon {
    position: absolute;
    width: 45px;
  }
  .video_icon .circle-outer {
    color: #e50040;
    font-weight: bolder;
    border-radius: 50%;
    /* margin: 0 auto 5px; */
    position: relative;
  
    left: 23px;
    bottom: 7px;
  }
  .video_icon .circle_inner {
    background: #e50040;
    left: 2px;
    bottom: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    opacity: 0.9;
  }
  .video_icon .circle_inner:after {
    content: "";
    display: block;
    border: 1px solid #e50040;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    top: -7px;
    left: -7px;
    position: absolute;
    opacity: 0.8;
    -webkit-animation: circle 2s ease-in-out 0.2s infinite;
    animation: circle 2s ease-in-out 0.2s infinite;
  }
  .video_icon p {
    color: #000;
    text-align: center;
  }

  @-webkit-keyframes circle {
    from {
      -webkit-transform: scale(1);
              transform: scale(1); }
    
    to {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      opacity: 0; } }
  
  @keyframes circle {
    from {
      -webkit-transform: scale(1);
      transform: scale(1);
      }
    
    to {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      opacity: 0;
      } }