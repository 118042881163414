.quote_img{
    height: 25px;
    width: 25px;
}

.info {
  text-align: justify;
  text-justify: inter-word;
  min-width: 320px;
}

/* Joint button */

.btn_hover {
  width: 200px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
  height: 48px;
  text-align: center;
  border: none;
  background-size: 400% 100%;
  border-radius: 50px;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn_hover:hover {
  background-position: 100% 0;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn_hover:focus {
  outline: none;
}

.btn_hover.color {
  background-image: linear-gradient(
    to right,
    #04befe,
    #1a47fc,
    #357cf5,
    #3b86f8
  );
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}
